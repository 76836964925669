<template>
<div>
     <template v-for="(item,index) in this.$store.state.admin.adminOrders">
    <OperatorRegistryItem :key="index" :nextStatus='3' currentStatus='status_id=[6]' :item="item"/>
     </template>
    <nav aria-label="Page navigation example ">
        <div class="pagination justify-content-center align-items-center d-none">
            <a href="#" class="pagination__prev"></a>
            <ul>
                <li>
                    <a href="#">1</a>
                </li>
                <li>
                    <a href="#">2</a>
                </li>
                <li>
                    <a href="#">3</a>
                </li>
                <li>
                    <a href="#">4</a>
                </li>
            </ul>
            <a href="#" class="pagination__next"></a>
        </div>
    </nav>
</div>
</template>

<script>
//import Navbar from '@/components/Navbar.vue'
//import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'

import OperatorRegistryItem from '@/components/OperatorRegistryItem.vue'

export default {

    data() {
        return {

        };
    },
          head: {
        title: {
            inner: 'Реестр',
            separator: '-',
            complement: 'Администратор'
        },
    },
    components: {
        // Navbar,
        //AdminMenuHorizont,

        OperatorRegistryItem

    },
     async mounted() {
          const data = {
            status_id: JSON.stringify([6]),
        }
       // if (this.$store.state.admin.dayOfWeek == 0) {
            delete data.date;
       // }
        this.$store.commit("loader", true);
        await this.$store.dispatch("actionGetAdminOrders", data);
        this.$store.commit("loader", false);
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.adminHeaderWrapper {}

.container {}

.adminHeader {
    margin-top: 27px;
    margin-bottom: 40px;

    &__btns {}

    &__btn {
        display: flex;
        justify-content: end;
    }
}

.btn-brown-border {
    @extend .btn;
    background: #3F2A2C;
    border-radius: 9px;
    font-size: 16px;
    font-family: Montserrat;
    color: #FFF;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.btn-white-border {
    @extend .btn;

    border-radius: 9px;
    border: 1px solid #000;
    font-size: 16px;
    font-family: Montserrat;
    color: #000;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.btn-gray {
    @extend .btn;
    border-radius: 9px;

    font-size: 16px;
    font-family: Montserrat;
    color: #000;
    padding-left: 37px;
    padding-right: 37px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #D5D5D5;
}

.btn-oval {}

.container {
    max-width: 1340px;
}

.g-3,
.gx-3 {
    --bs-gutter-x: .6rem;
}

.pagination__prev,
.pagination__next {
    display: block;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 1px solid $orange;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 45px;

    &:before {
        position: absolute;
        content: '';
        border: solid #fd7e14;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(135deg);
    }
}

.pagination__next {
    margin-left: 45px;

    &:before {
        position: absolute;
        content: '';
        border: solid #fd7e14;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
    }
}

.pagination {
    margin-top: 70px;
    margin-bottom: 53px;

    ul {
        display: flex;
        list-style: none;
        justify-content: center;
        padding: 0;
        margin: 0;

        li {
            border: none;
            margin-left: 23px;
            margin-right: 23px;

            a {
                border: none;
                font-family: Montserrat;
                font-size: 16px;
                color: #000000;
                text-decoration: none;
                display: flex;
                line-height: 1;

                b {
                    // font-size: 24px;
                }
            }
        }
    }
}

.adminOrderItem {
    max-width: 310px;
}

.adminHeaderWrapper {
    h1 {
        font-size: 40px;
        line-height: 100.74%;
        letter-spacing: 2px;
        margin-top: 30px;
        margin-bottom: 50px;
    }
}
</style>

<template>
<div class="admin-order-wrapper">
    <div class="container">
        <div class="row adminOrder mb-2">
            <div class="col-md-12 adminOrder__header">
                <div class="adminOrder__header-number">
                    <b>Заказ №{{item.unique_code}}</b>
                    <p>{{item.type_of_eating.title}} ({{item.type_of_eating.time_start}}-{{item.type_of_eating.time_end}})</p>
                   <p>   {{$moment(item.date_day).format('DD.MM.YYYY')}}</p>
                    <div class="showFullOrder mt-2" @click="toggle(item.id)" :class="{ opened: opened.includes(item.id) }">
                        <span v-if="opened.includes(item.id)">{{item.status.name}}</span>
                        <span v-else>{{item.status.name}}</span>
                    </div>
                </div>
            </div>
            <div class="col-md-12" v-if="opened.includes(item.id)">
                <div class="row gx-3">
                    <div class="col-md-3" v-for="good in item.products" :key="good.id">
                        <div class="adminOrderItem">
                            <div class="adminOrderItem__number adminOrderItem__number--orange">
                                <img :src="require('../assets/imageOpct.jpg')" />
                            </div>
                            <div class="adminOrderItem__desc">
                                <b>{{good.name}}</b>
                                <span>{{good.quantity}} {{decQua(good.quantity)}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="adminOrder__btn">
                    <button class="btn-oval" @click="collected(1,item.id)">Оформить возврат</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
//import Navbar from '@/components/Navbar.vue'
//import AdminMenuHorizont from '@/components/AdminMenuHorizont.vue'
//import FoodItemOperator from '@/components/FoodItemOperator.vue'
export default {

    data() {
        return {
            opened: [],
            rows: []
        };
    },
    props: {
        item: Object,
        nextStatus: Number,
        currentStatus: String
    },
    components: {
        // Navbar,
        //AdminMenuHorizont,
        //  FoodItemOperator,

    },
   watch:{
      '$store.state.admin.adminOrders': {
            //immediate: true,
            async handler() {
              const id = this.$store.state.admin.adminOrders[0].id
        this.opened.push(id)
            }
        },
    },
    methods: {
        toggle(id) {
            const index = this.opened.indexOf(id);
            if (index > -1) {
                this.opened.splice(index, 1)
            } else {
                this.opened.push(id)
            }
        },
        decQua(count) {
            if (count == 1) {
                return 'порция';
            } else if (count > 1 && count <= 4) {
                return 'порции';
            } else if (count >= 5) {
                return 'порций';
            }
        },
          async collected(status_id, order_id) {

            await this.$store.dispatch("actionChangeStatus", {'status_id': status_id,'order_id': order_id});
            // await this.$store.dispatch("actionGetListOrder", this.currentStatus);
            const data = {
                status_id: [6],
            }
            this.$store.commit("loader", true);
            await this.$store.dispatch("actionGetAdminOrders", data);
            this.$store.commit("loader", false);

        },
    }
}
</script>

<style lang="scss">
.admin-order-wrapper {
    background: #F5F5F5;
}

.adminOrder {
    background: #F5F5F5;
    padding-top: 25px;
    padding-bottom: 3px;

    &__header {
        display: flex;
        justify-content: space-between;
        //align-items: center;
        font-family: Montserrat;
    }

    &__header-number {
        font-size: 16px;
        margin-bottom: 17px;

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__header-time {
        font-size: 18px;
        color: #000;
        font-weight: 700;
    }

    .orange {
        color: $orange;
    }

    &__btn {
        margin-top: 20px;
        padding-bottom: 29px;
    }
}

.showFullOrder {
    font-weight: 500;
    font-size: 16px;
    font-family: Montserrat;
    cursor: pointer;
}

.adminOrderItem {
    display: flex;
    font-family: Montserrat;
    background: #fff;
    opacity: .5;
    padding: 15px;
    height: 102px;
    border-radius: 9px;
    min-width: 234px;
    align-items: center;
    margin-bottom: 10px;

    &__number {
        width: 88px;
        height: 72px;
        background: #000;
        color: #fff;
        font-weight: bold;
        font-size: 24px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;

        &--orange {
            background: $orange;
        }

        &--blue {
            background: #0041E8;
        }
    }

    &__desc {
        padding-left: 13px;

        b {
            display: block;

        }

        span {
            display: block;
        }

        &--center {
            width: 100%;
            text-align: center;
        }
    }
}
</style>
